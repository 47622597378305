import React, { useEffect, useState } from "react";
import FooterComponent from "../../components/ui/footer";
import HeaderUIComponent from "../../components/ui/header/header.ui";
import { Button, Layout, Table, Tooltip, Modal, notification } from "antd";
import axios from "axios";
import { files_api } from "../../services/apis";
import { useNavigate, useParams } from "react-router";
import SpinnerComponent from "../../components/spinner/spinner.component";
import SmallSpinnerComponent from "../../components/spinner/small-spinner.component";
import FilenameComponent from "../../components/ui/filename.component";
import AudioPlayer from "../../components/audio-player/audio-player.component";
import DraftScriptModal from "../../components/modal/draft-scirpt-modal";
import VideoPlayer from "../../components/video/video-player";
import CommentsComponent from "../../components/comments/comments.component";
import { useSelector } from "react-redux";
import { FaComment, FaCommentSlash } from "react-icons/fa";
import {
  removeUserDetailsFromLocalStorage,
  saveUserDetailsToLocalStorgae,
} from "../../services/save-user";
import { FloatButton } from "antd";
import { FaMusic } from "react-icons/fa6";
import { FaVideo, FaFileAlt } from "react-icons/fa";

const SharedFiles = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [sharedFiles, setSharedFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [commentData, setCommentData] = useState({});
  const [commnetObjId, setCommnetObjId] = useState("");
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [tempSelected, setTempSelected] = useState([]);

  const [api, contextHolder] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    api[type]({
      message,
      placement: "top",
    });
  };

  const isPlayingStatus = useSelector((state) => state.playingStatusReducer);

  useEffect(() => {
    fetchFiles();

    return () => {
      cleanup();
    };
  }, []);

  const fetchFiles = async () => {
    try {
      setIsLoading(true);
      let res = await axios.get(`${files_api.sharedFiles}/${id}`);

      if (res.data && res.data.file) {
        localStorage.setItem("loggedIn", "false");
        localStorage.removeItem("isBackTranslation");
        localStorage.removeItem("checkPageMsg");
        await removeUserDetailsFromLocalStorage();

        const communityCheckFiles = res.data.file.files
          .filter((file) => file.communityStatusCheck === true)
          .map((file) => file._id);
        console.log("communityCheckFiles", communityCheckFiles);

        setSelectedFiles(communityCheckFiles);

        setSharedFiles(
          res.data.file.files.map((file) => ({
            ...file,
            key: file._id,
            disabled: file.communityStatusCheck === true,
          }))
        );

        let receiver = res.data.file.receiver;
        localStorage.setItem("email", receiver.email);
        localStorage.setItem("firstName", receiver.firstName);
        localStorage.setItem("lastName", receiver.lastName);
        localStorage.setItem("role", receiver.role);
        localStorage.setItem("id", receiver._id);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const cleanup = () => {};

  const toggleComments = (data, id) => {
    if (showComments == true && commnetObjId == id) {
      setShowComments(false);
    } else if (showComments == true) {
      setCommentData(data);
      setCommnetObjId(id);
    } else {
      setShowComments(true);
      setCommentData(data);
      setCommnetObjId(id);
    }
  };

  const handleFileSelection = (selectedRowKeys) => {
    setTempSelected(selectedRowKeys);
    setIsConfirmModalVisible(true);
  };

  const handleConfirmSelection = async () => {
    try {
      setModalLoading(true);
      console.log("Selected Files:", selectedFiles);

      let res = await axios.post(`${files_api.markCommunityCheck}`, {
        userId: localStorage.getItem("id"),
        checkType: "COMMUNITY_CHECK",
        fileIds: selectedFiles,
      });
      setSelectedFiles(tempSelected);
      setIsConfirmModalVisible(false);
      showNotification("success", "Community check done successfully");
    } catch (error) {
      console.log("Error:", error);
      showNotification("error", error.response.data.msg);
    } finally {
      setModalLoading(false);
    }
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      render: (value, record) => <FilenameComponent file={record} />,
    },
    {
      title: (
        <span className={`flex items-center justify-end mr-4-5 w-full`}>
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      render: (item, record) => (
        <div className="flex items-center justify-end mr-10">
          <p>
            {record && record.fileType && record.fileType == "audio" ? (
              <AudioPlayer
                revokeOtherFunctions={null}
                data={record}
                audioType={record.fileType}
                isDisabled={isTableLoading}
                isReverse={false}
                reload={fetchFiles}
              />
            ) : null}
          </p>
          <p className="flex items-center justify-end mr-10">
            {record && record.fileType && record.fileType == "script" ? (
              <DraftScriptModal data={record} isDisabled={isLoading} />
            ) : null}
          </p>
          <p className="flex items-center justify-end mr-10">
            {record && record.fileType && record.fileType == "video" ? (
              <VideoPlayer data={record} fileType="DRAFTFILE" />
            ) : null}
          </p>

          <>
            <Tooltip placement="topLeft" title="Comment">
              <Button
                className="ml-2"
                disabled={isPlayingStatus}
                onClick={(e) => {
                  toggleComments(record, record.fileName);
                }}
              >
                {showComments == true && commnetObjId == record.fileName ? (
                  <FaCommentSlash size={16} />
                ) : (
                  <FaComment size={16} />
                )}
              </Button>
            </Tooltip>
          </>
        </div>
      ),
    },
  ];

  const redirectToPage = async (type) => {
    try {
      if (type === "PLAY_ALL") {
        let audioFiles = await sharedFiles.filter(
          (item) => item.fileType == "audio"
        );

        navigate("/audio-playlist", {
          state: {
            audioFiles: audioFiles,
          },
        });
      } else if (type === "READ_ALL") {
        let scriptFiles = await sharedFiles.filter(
          (item) => item.fileType == "script"
        );

        navigate("/script-view", {
          state: {
            scriptFiles: scriptFiles,
          },
        });
      } else if (type === "VIEW_ALL") {
        let videoFiles = await sharedFiles.filter(
          (item) => item.fileType == "video"
        );

        navigate("/video-player", {
          state: {
            videoFiles,
          },
        });
      }
    } catch (error) {}
  };

  return (
    <div className="layout">
      <Layout>
        <Layout.Header>
          <HeaderUIComponent />
        </Layout.Header>
        <Layout>
          <>
            <Layout.Content>
              <div className="sharedFilesPage">
                {isLoading == true ? (
                  <SpinnerComponent />
                ) : (
                  <div className="mt-4">
                    <h4>Shared Files</h4>
                    <Table
                      rowSelection={{
                        type: "checkbox",
                        selectedRowKeys: selectedFiles,
                        onChange: handleFileSelection,
                        hideSelectAll: true,
                        getCheckboxProps: (record) => ({
                          disabled: record.disabled,
                          hideSelectAll: true,
                        }),
                      }}
                      loading={{
                        spinning: isTableLoading,
                        indicator: (
                          <span>
                            <SmallSpinnerComponent />
                          </span>
                        ),
                      }}
                      pagination={{ pageSize: 5 }}
                      columns={columns}
                      dataSource={sharedFiles}
                    />

                    <div className="pb-2 mx-2">
                      {showComments && (
                        <CommentsComponent chunk={commentData} />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Layout.Content>
          </>
        </Layout>
        <Layout.Footer>
          <FooterComponent />
        </Layout.Footer>
      </Layout>

      <Modal
        title="Confirm Community Check"
        open={isConfirmModalVisible}
        onOk={handleConfirmSelection}
        confirmLoading={modalLoading}
        onCancel={() => setIsConfirmModalVisible(false)}
      >
        <p>Are you sure you want to mark as Community Check?</p>
      </Modal>

      {contextHolder}

      <>
        <FloatButton.Group
          trigger="click"
          type="primary"
          style={{
            insetInlineEnd: 24,
          }}
        >
          <FloatButton
            icon={<FaFileAlt  color="white"/>}
            tooltip="Read All"
            onClick={() => redirectToPage("READ_ALL")}
          />
          <FloatButton
            icon={<FaMusic color="white" />}
            tooltip="Play All"
            style={{ color: "white" }}
            onClick={() => redirectToPage("PLAY_ALL")}
          />
          <FloatButton
            icon={<FaVideo  color="white"/>}
            tooltip="View All"
            onClick={() => redirectToPage("VIEW_ALL")}
          />
        </FloatButton.Group>
      </>
    </div>
  );
};

export default SharedFiles;
