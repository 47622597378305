import { Button, Form, Modal, notification, Select } from "antd";
import React, { useEffect, useState } from "react";
import { FaShareAlt } from "react-icons/fa";
import { postShareFiles } from "../../../services/api-calls/draft-files.api";

const FileShare = ({ chapterName, bookName, project, users }) => {
  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState();
  const [selectedBookName, setSelectedBookName] = useState();
  const [seleectedChapterName, setSeleectedChapterName] = useState();
  const [otherUsers, setOtherUsers] = useState([]);

  const [api, contextHolder] = notification.useNotification();
  const showNotifications = (type, message) => {
    notification.destroy();
    api[type]({
      message,
      placement: "top",
    });
  };

  useEffect(() => {
    form.resetFields(["shareType"]);
    if (project) setCurrentProject(project);
    if (bookName) setSelectedBookName(bookName);
    if (chapterName) setSeleectedChapterName(chapterName);
    if (users) setOtherUsers(users);

    return () => {
      setCurrentProject({});
      setSelectedBookName("");
      setSeleectedChapterName("");
      setOtherUsers([]);
    };
  }, []);

  const onFinish = async (data) => {
    try {
      let body = {
        ...data,
        ...{
          bookName: selectedBookName,
          chapterName: seleectedChapterName,
          projectId: currentProject._id,
          sender: localStorage.getItem("id"),
        },
      };

      console.log("body", body);
      let res = await postShareFiles(body);
      if (data?.shareType === "others") {
        if (navigator?.share) {
          const sharedFileUrl = `${window.location.protocol}//${window.location.host}/shared-files/${res?.sharedFiles?._id}`;

          navigator
            .share({
              title: "File Sharing",
              url: sharedFileUrl,
            })
            .then(() => console.log("File shared successfully"))
            .catch((error) => console.error("Error sharing file:", error));
        } else {
          console.error("Web Share API is not supported in this browser.");
        }
      } else {
        console.error("Invalid or missing shareType in the data.");
      }

      form.resetFields();
      showNotifications("success", "Files shared successfully");
      setIsModalOpen(false);
    } catch (error) {
      console.log(error);
      showNotifications("error", "Something went wrong");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <span className="flex items-center">
        <FaShareAlt
          size="20"
          className="mr-2"
          onClick={(e) => {
            setIsModalOpen(true);
          }}
        />
      </span>

      <Modal title={"Share File"} open={isModalOpen} footer={<></>}>
        <div className="mb-2">
          <p className="heading capitalize">
            Project Name:
            {currentProject && currentProject.projectName
              ? `  ${project.projectName}`
              : ""}
          </p>
          <p className="heading capitalize">Book : {selectedBookName}</p>
          <p className="heading capitalize">Chapter: {seleectedChapterName}</p>
        </div>

        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          requiredMark="optional"
        >
          <Form.Item
            className="w-full"
            label={<span style={{ fontWeight: "600" }}>User</span>}
            name="receiver"
            rules={[{ required: true, message: `Please select user` }]}
          >
            <Select
              showSearch
              className="w-full"
              style={{ marginTop: "-7px" }}
              optionFilterProp="children"
              placeholder="Please select share option"
            >
              {otherUsers && otherUsers.length > 0 ? (
                <>
                  {otherUsers.map((user) => (
                    <Select.Option value={user._id}>
                      <span> {user.firstName}</span>
                      <span className="mx-2"> {user.lastName}</span>
                    </Select.Option>
                  ))}
                </>
              ) : null}
            </Select>
          </Form.Item>

          <Form.Item
            className="w-full"
            label={
              <span style={{ fontWeight: "600", marginBottom: "-10px" }}>
                Share Option
              </span>
            }
            name="shareType"
            rules={[{ required: true, message: `Please select share option` }]}
          >
            <Select
              showSearch
              className="w-full"
              style={{ marginTop: "-7px" }}
              optionFilterProp="children"
              placeholder={"Please select share option"}
              options={[
                { value: "email", label: `Email` },
                { value: "sms", label: `SMS` },
                { value: "others", label: `Others` },
              ]}
            />
          </Form.Item>

          <div className="w-full flex flex-row mt-4">
            <Button
              htmlType="button"
              className="submit-btn w-full mr-2"
              danger
              style={{
                background: "red",
                color: "white",
                borderColor: "red",
              }}
              onClick={(e) => {
                setIsModalOpen(false);
                form.resetFields();
              }}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              className="submit-btn w-full ml-2"
              style={{
                background: "#031832",
                color: "white",
                borderColor: "#031832",
              }}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal>

      {contextHolder}
    </div>
  );
};

export default FileShare;
