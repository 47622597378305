import React, { useEffect, useRef, useState } from "react";
import Moment from "moment";
import { Button, Select, notification, Modal } from "antd";
import { IoMdMicOff } from "react-icons/io";
import SingleWaveform from "../../components/waveform/single.waveform";
import MicRecorder from "mic-recorder-to-mp3";
import {
  getCurrentTimeStamp
  , randomHEXID,
  waitForAWhile
} from "../../services/config";
import { useLocation } from "react-router";
import { bufferToBase64, convertBlobToBase64, b64toBlob } from "../../services/conversion.service";
// import {
//   saveDraftFileAndSync,
// } from "../../services/sync-methods/draft-files.sync";
import { MdOutlineAudiotrack } from "react-icons/md";
// import { saveCommentAndSync } from "../../services/sync-methods/comments.sync";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleRecording,
  toggleSyncStatus,
  tranScriptedText,
  clearTranScriptedText
} from "../../redux/actions/util.action";
import { postCommentToServer } from "../../services/api-calls/comments.api"
import { uploadDraftFileToServer, saveDraftFileToServer, getDraftedFilesByChunksOnline } from "../../services/api-calls/draft-files.api"
import {
  VoiceRecorder,
  VoiceRecorderPlugin,
  RecordingData,
  GenericResponse,
  CurrentRecordingStatus,
} from "capacitor-voice-recorder";
import SpinnerComponent from "../spinner/spinner.component";
import { countryCode } from "../../services/country-code";
import "../draft-page-component/draft-script/script.scss"
import { voiceCloning } from "../../services/apis";
import axios from "axios";
//import CloneVoiceModal from "../modal/clone-voice-modal/cloneVoiceModal";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const AudioRecorder = ({
  isDisabled,
  showMessage,
  chunk,
  project,
  relatedDraft,
  reload,
  resultantNumber,
  setParentLoading,
  targetType,
  onClick,
  record_type
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [secs, setSecs] = useState(0);
  const location = useLocation();
  const countRef = useRef(null);
  const speechRef = useRef();
  const [isPaused, setIsPaused] = useState(false);
  const mediaRecorder = useRef();
  const dispatch = useDispatch();
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultLang,setDefaultLang]=useState("en-US")
  const isPlayingStatus = useSelector((state) => state.playingStatusReducer);
  const isTranScriptedText = useSelector((state) => state.tranScriptedText);
  const [isVoiceClonedModalOpen, setIsVoiceClonedModalOpen] = useState(false)
const [isAudioCloning, setIsAudioCloning] = useState(false)

  const isRecordingStatus = useSelector(
    (state) => state.recordingStatusReducer
  );
  let audioChunk = [];
  let audioUrl;
  let capitalizeCountry;

  useEffect(() => {
      // setDefaultLanguage();
      role=="MTT" && targetType=="draft" && speechAudioToText();    
  }, [targetType]);

  const [notificationApi, NotificationJSX] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    notificationApi[type]({
      message,
    });
  };

  const setDefaultLanguage = async ()=>{
    capitalizeCountry = await location.state.project.country.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    let checkLanguage = await countryCode.find((item) => item.country == capitalizeCountry && item.language == location.state.project.majorLanguage1);
    if(checkLanguage){
      speechRef.current.lang = await checkLanguage.two_letter;
      setDefaultLang(checkLanguage.two_letter);
    }else{
      speechRef.current.lang = await "en-US";    }
  }
  // const askPermission = () => {
  //   VoiceRecorder.canDeviceVoiceRecord().then((result) =>
  //     console.log(result.value)
  //   );
  //   VoiceRecorder.requestAudioRecordingPermission().then(
  //     (result) => console.log(result.value)
  //   );
  // };
  const clearExistingTranScript = async (data)=> {
    let value;
    if(isTranScriptedText && isTranScriptedText.length > 0){
      value = await isTranScriptedText.filter((item)=> item.record_type != data.record_type);
      return [...value,data]
    }else{
      return [data]
    }
  }

  const speechAudioToText = async () => {
    let interim_transcript = '';
    let final_transcript = '';
    speechRef.current = await new window.webkitSpeechRecognition();
    speechRef.current.continuous = true;
    speechRef.current.interimResults = true;
    let code;
    console.log("In speech recognition")

    if (role == "MTT") {
      const capitalizeCountry = await location.state.project.country.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      const capitalizeLanguage = await location.state.project.language.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      const code = await countryCode.find((item) => item.country == capitalizeCountry && item.language == capitalizeLanguage);
      // const capitalizeLanguage = await location.state.project.language.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      // code = await countryCode.find((item) => item.country == capitalizeCountry && item.language == capitalizeLanguage);
      speechRef.current.lang = await code.two_letter;
    }
    console.log("In speech recognition speechRef.current",speechRef.current.lang)
    let data;

    speechRef.current.onresult = async (event) => {
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        console.log("Event :",event)
        if (event.results[i].isFinal) {
          final_transcript += event.results[i][0].transcript;
          data = {
            targetfileType: targetType == "draft" ? "draft" : targetType == "masterRecord" ? "masterRecord" : chunk.fileType == "audio" ? "audio" : "script",
            final_transcript: final_transcript,
            record_type
          }
          console.log("onresult",data);
        } else {
          interim_transcript += event.results[i][0].transcript;
        }
      }
      final_transcript = '';
    };


    speechRef.current.onend = async () => {
      // Start a new recognition session when the current one ends
      speechRef.current.stop(); 
      if(targetType != "COMMENTFILE"){
        console.log("Data :",data)
        console.log("Cleared")
      let result = await clearExistingTranScript(data);
      await dispatch(clearTranScriptedText());
      await dispatch(tranScriptedText(result));
      }
    };
  }

  const displayMessage = (type, content) => {
    if (showMessage) {
      showMessage(type, content);
    } else {
      showNotification(type, content);
    }
  };

  //First Code
  const startRecording = async () => {
    try {
      if (isBlocked) {
        displayMessage("error", "Please grant microphone permission");
      } else if (isPlayingStatus == true) {
        displayMessage("error", "Please stop playing audio");
      } else if (isRecordingStatus == true) {
        displayMessage("error", "Please stop recording ");
      } else {
        console.log("speechRef.current: ",speechRef.current)
        let status = await Mp3Recorder.start();
        console.log("status : ",status);
        role=="MTT" && targetType=="draft" && await speechRef.current.start();
        console.log("start : ",speechRef.current);
        if (onClick) onClick();
        displayMessage("success", "Recording Started Successfully");
        dispatch(toggleRecording(true));
        countRef.current = setInterval(() => {
          setSecs((secs) => secs + 1);
        }, 1000);
        setIsRecording(true);
      }
    } catch (error) {
      console.log("Start Recording error ", error);
      displayMessage("error", `${error}`);
    }
  };

  const stopRecording = async () => {
    clearInterval(countRef.current);
    setIsRecording(false);
    dispatch(toggleRecording(false));
    try {
      Mp3Recorder.stop()
        .getMp3()
        .then(async ([buffer, blob]) => {
          role=="MTT" && targetType=="draft" && await speechRef.current.stop();
          await setIsModalOpen(false);
          if (secs > 0) {
            if (targetType == "COMMENTFILE") {
              await saveCommentFile(blob);
            } else {
              await saveAudioFile(blob);
            }
          } else {
            showMessage("error", "Please record more than 1 second");
          }
          // interim_transcript = '';
          // final_transcript = '';
        })
        .catch((e) => console.log(e));
      setSecs(0);
    } catch (error) {
      console.log("Stop Recording Permission ", error);
      showMessage("error", "Something went wrong , Please try agian");
    }
  };

  const saveCommentFile = async (blob) => {
    return new Promise(async (resolve, reject) => {
      // setParentLoading(true);
      try {
        let base64Data = await bufferToBase64(blob);
        let uniqueKey = await getCurrentTimeStamp();
        let commentFileName = `${await chunk.fileName}____${uniqueKey}.mp3`;

        let commentObj = {
          fileName: commentFileName,
          role: localStorage.getItem("role"),
          userId: localStorage.getItem("id"),
          fileId: uniqueKey,
          user: `${localStorage.getItem("firstName")}  ${localStorage.getItem(
            "lastName"
          )} (${localStorage.getItem("role")})`,
          fileType: "audio",
          comments: "",
          time: secs,
          createdAt: new Date().toISOString(),
          projectName:location && location.state?location.state.project.projectName:null// only this field will not update in DB
        };

        if (navigator.onLine == true) {
          let commentRes = await postCommentToServer(chunk?.fileId, {
            ...commentObj,
            base64: base64Data,
          });
          // await saveCommentToIndexedDb(fileId, commentRes?.result);
          // resolve(true);
        }

        displayMessage("success", "Comment submitted successfully");
        dispatch(toggleSyncStatus(true));
        setTimeout(() => {
          dispatch(toggleSyncStatus(false));
        }, 5000);
        await reload();
        resolve(true);
      } catch (error) {
        console.log(error);
        resolve(true);
        displayMessage("error", "Error occurred while saving");
        await reload();
      }
    });
  };

  const saveAudioFile = async (blob) => {
    return new Promise(async (resolve, reject) => {
      try {
        let draftFileKey;
        if (relatedDraft) {
          let splittedName = relatedDraft.split(
            `${location.state?.chunkName} ${resultantNumber}`
          )[1];
          draftFileKey = splittedName.split(".mp3")[0];
        }
        let uniqueKey = await getCurrentTimeStamp();
        let base64Data = await bufferToBase64(blob);
        let audioObj = {
          key: uniqueKey,
          name: `${location.state?.chunkName} ${resultantNumber} ${uniqueKey}`,
          chunk: chunk,
          time: secs,
          blob: blob,
          formattedName: `${location.state?.chunkName} ${resultantNumber}`,
          bookName: location.state?.chunk.chunk[0].bookName,
          comments: [],
          chapter: location.state?.chunk.chunk[0].chapterName,
          language: project.language,
          majorLanguage: project.majorLanguage1,
          projectId: project._id,
          fileId: uniqueKey,
          sourceVerseName: location.state?.verse,
          verse: location.state?.verse,
          userId: localStorage.getItem("id"),
          targetType: targetType,
          projectType: project.projectType,
          fileName: `${location.state?.chunkName} ${resultantNumber} ${uniqueKey}.mp3`,
          isSelected: false,
          fileType: "audio",
          isSynced: false,
          relatedDraft: relatedDraft,
          createdAt: new Date().toISOString(),
        };
        setParentLoading(true);

        await saveDraftFileToServer(audioObj)
        // await saveDraftFileAndSync(audioObj);

        showMessage("success", "File saved successfully");
        await reload();
        dispatch(toggleSyncStatus(true));
        setTimeout(() => {
          dispatch(toggleSyncStatus(false));
        }, 5000);
      } catch (error) {
        console.log("Error while saving audio file", error);

        showMessage("error", "Error while saving audio file");
        await reload();
      }
      resolve();
    });
  };

  const selectTypeOfAudio = async () => {
    try {
      await startRecording();
    } catch (error) {
      console.log("ERROR", error);
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChange = async (value) => {
    speechRef.current.lang = await value;
  };

  //Native Voice Recorder
  // const startRecording = async () => {
  //   try {
  //     if (isBlocked) {
  //       displayMessage("error", "Please grant microphone permission");
  //     } else if (isPlayingStatus == true) {
  //       displayMessage("error", "Please stop playing audio");
  //     } else if (isRecordingStatus == true) {
  //       displayMessage("error", "Please stop recording ");
  //     } else {
  //       // let status = await Mp3Recorder.start();
  //       if (onClick) onClick();
  //       displayMessage("success", "Recording Started Successfully");
  //       dispatch(toggleRecording(true));
  //       countRef.current = setInterval(() => {
  //         setSecs((secs) => secs + 1);
  //       }, 1000);
  //       setIsRecording(true);
  //       navigator.mediaDevices.getUserMedia({
  //         audio:true
  //       })
  //         .then(async (stream) => {

  //           // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  //           // return new MediaRecorder(stream);
  //           mediaRecorder.current = new MediaRecorder(stream,{
  //             mimeType:"video/mp3"
  //           });
  //           mediaRecorder.current.ondataavailable = (event) => {
  //             audioChunk.push(event.data);
  //           };

  //           await mediaRecorder.current.start(1000);
  //           mediaRecorder.current.onstop = async () => {
  //             stream.getTracks().forEach(track => track.stop());
  //             const audioBlob = await new Blob(audioChunk, { type: 'audio/mp3' });
  //             audioUrl = await convertBlobToBase64(audioBlob);
  //             // if (secs > 0) {
  //             if (targetType == "COMMENTFILE") {
  //               await saveCommentFile(audioUrl);
  //             } else {
  //               await saveAudioFile(audioUrl);
  //             }
  //             // } else {
  //             //   showMessage("error", "Please record more than 1 second");
  //             // }
  //             // audioUrl = await URL.createObjectURL(audioBlob);
  //           };
  //         })
  //         .catch(error => console.error("Error starting recording:", error));
  //     }
  //   } catch (error) {
  //     console.log("Start Recording error ", error);
  //     displayMessage("error", `${error}`);
  //   }
  // };

  // const handlePauseResume = () => {
  //   if (isPaused) {
  //     countRef.current = setInterval(() => {
  //       setSecs((secs) => secs + 1);
  //     }, 1000);
  //     mediaRecorder.current.resume();
  //   } else {
  //     clearInterval(countRef.current);
  //     mediaRecorder.current.pause();
  //   }
  //   setIsPaused(!isPaused);
  // };

  // const handleStopRecording = async () => {

  //   await mediaRecorder.current.stop();
  //   dispatch(toggleRecording(false));
  //   setIsRecording(false);
  //   setIsPaused(false);
  //   setSecs(0);
  //   await clearInterval(countRef.current);
  //   // if (secs > 0) {
  //   //   console.log("COMMENTFILE", audioUrl);
  //   //   if (targetType == "COMMENTFILE") {
  //   //     await saveCommentFile(audioUrl);
  //   //   } else {
  //   //     await saveAudioFile(audioUrl);
  //   //   }
  //   // } else {
  //   //   showMessage("error", "Please record more than 1 second");
  //   // }
  // };

  // const startRecordingWithPermission = async () => {
  //   try {
  //     const microphonePermission = await navigator.permissions.query({
  //       name: "microphone",
  //     });

  //     if (microphonePermission.state === "granted") {
  //       // User has already granted permission
  //       startRecording_1();
  //     } else if (microphonePermission.state === "prompt") {
  //       // Permission has not been granted or denied yet, show a prompt
  //       const userPermission = await navigator.mediaDevices.getUserMedia({
  //         audio: true,
  //       });

  //       if (userPermission) {
  //         // Permission granted by the user
  //         startRecording_1();
  //       } else {
  //         // Permission denied by the user
  //         console.error("Microphone permission denied by the user");
  //       }
  //     } else {
  //       // Permission denied by the user
  //       console.error("Microphone permission denied by the user");
  //     }
  //   } catch (error) {
  //     console.error("Error requesting microphone permission:", error);
  //   }
  // };


  //capacitor voice recorder
  // const startRecording_1 = () => {
  //   try {
  //     if (isBlocked) {
  //       displayMessage("error", "Please grant microphone permission");
  //     } else if (isPlayingStatus == true) {
  //       displayMessage("error", "Please stop playing audio");
  //     } else if (isRecordingStatus == true) {
  //       displayMessage("error", "Please stop recording ");
  //     } else {
  //       // let status = await Mp3Recorder.start();
  //       if (onClick) onClick();
  //       displayMessage("success", "Recording Started Successfully");
  //       dispatch(toggleRecording(true));
  //       // countRef.current = setInterval(() => {
  //       //   setSecs((secs) => secs + 1);
  //       // }, 1000);
  //       // setIsRecording(true);

  //       // setIsShow(true);
  //       // localStorage.setItem("isShowText", JSON.stringify(false))
  //       setIsRecording(true);
  //       countRef.current = setInterval(() => {
  //         setSecs((secs) => secs + 1);
  //       }, 1000);
  //       VoiceRecorder.startRecording()
  //         .then((result) => console.log(result.value))
  //         .catch((error) => console.log(error));

  //       // navigator.mediaDevices.getUserMedia({
  //       //   audio: {
  //       //     echoCancellation: true,
  //       //     noiseSuppression: true,
  //       //     sampleRate: 44100
  //       //   }
  //       // })
  //       //   .then(async (stream) => {
  //       //     mediaRecorder.current = new MediaRecorder(stream, {
  //       //       mimeType: 'audio/webm'
  //       //     });
  //       //     mediaRecorder.current.ondataavailable = (event) => {
  //       //       audioChunk.push(event.data);
  //       //     };

  //       //     await mediaRecorder.current.start(1000);
  //       //     mediaRecorder.current.onstop = async () => {
  //       //       stream.getTracks().forEach(track => track.stop());
  //       //       const audioBlob = await new Blob(audioChunk, { type: 'audio/wav' });
  //       //       audioUrl = await convertBlobToBase64(audioBlob);
  //       //       await waitForAWhile(1000)
  //       //       if (targetType == "COMMENTFILE") {
  //       //         await saveCommentFile(audioUrl);
  //       //       } else {
  //       //         await saveAudioFile(audioUrl);
  //       //       }
  //       //     };
  //       //   })
  //       //   .catch(error => console.error("Error starting recording:", error));
  //     }
  //   } catch (error) {
  //     console.log("Start Recording error ", error);
  //     displayMessage("error", `${error}`);
  //   }
  // };

  // const stopRecording_1 = async () => {
  //   try {
  //     // setIsShow(false);
  //     // localStorage.setItem("isShowText", JSON.stringify(true));
  //     dispatch(toggleRecording(false));
  //     setIsRecording(false);
  //     clearInterval(countRef.current);
  //     setIsPaused(false);
  //     setSecs(0);
  //     let { value } = await VoiceRecorder.stopRecording();
  //     // let base64 = `${value.mimeType}${value.recordDataBase64}`
  //     if (targetType === "COMMENT") {
  //       await saveCommentFile(value.recordDataBase64);
  //     } else {
  //       await saveAudioFile(value.recordDataBase64);
  //     }
  //   } catch (error) {
  //     console.log("Error while stop recording", error);
  //   }
  // };

  // const pauseRecording_1 = () => {
  //   // setIsPaused(prevIsPaused => !prevIsPaused);
  //   if (isPaused == false) {
  //     VoiceRecorder.pauseRecording()
  //       .then((result) =>
  //         console.log("Is Paused ", result.value)
  //       )
  //       .catch((error) => console.log(error));
  //     clearInterval(countRef.current);
  //     setIsPaused(true);
  //   } else {
  //     countRef.current = setInterval(() => {
  //       setSecs((secs) => secs + 1);
  //     }, 1000);
  //     VoiceRecorder.resumeRecording()
  //       .then((result) =>
  //         console.log("Is Resumed", result.value)
  //       )
  //       .catch((error) => console.log(error));
  //     setIsPaused(false);
  //   }
  // };

  // const saveDraftFileToServer = (body) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       let fileObj = body;
  //       let blob = body.blob;

  //       delete body.blob;
  //       if (window.navigator.onLine == true) {
  //         if (body.fileType == "audio" || body.fileType == "video") {
  //           let base64 = await bufferToBase64(blob);
  //           body = { ...body, ...{ base64 } };
  //         } else {
  //           body = { ...body, ...{ base64: blob } };
  //         }
  //         let res = await uploadDraftFileToServer(body);
  //         resolve();
  //       }
  //     } catch (error) {
  //       console.log("ERROR", error);
  //     }
  //   })
  // }



 

  const recordWithAI = async()=>{
    try { 

      let selectedScript = localStorage.getItem("selectedScript")
      if(!selectedScript) {
        showNotification("error","Please add a script file")
        return
      }
      setIsAudioCloning(true)
      let uniqueKey = await getCurrentTimeStamp();

      let body = {  
        text:selectedScript,
          name: `${location.state?.chunkName} ${resultantNumber} ${uniqueKey}`,
          chunk: chunk,
          time: secs, 
          formattedName: `${location.state?.chunkName} ${resultantNumber}`,
          bookName: location.state?.chunk.chunk[0].bookName,
          comments: [],
          chapter: location.state?.chunk.chunk[0].chapterName,
          language: project.language,
          targetLanguage: project.language,
          majorLanguage: project.majorLanguage1,
          projectId: project._id,
          fileId: uniqueKey,
          sourceVerseName: location.state?.verse,
          verse: location.state?.verse,
          userId: localStorage.getItem("id"),
          targetType: targetType,
          projectType: project.projectType,
          fileName: `${location.state?.chunkName} ${resultantNumber} ${uniqueKey}.mp3`,
          isSelected: false,
          fileType: "audio",
          isSynced: false,
          relatedDraft: relatedDraft,
          createdAt: new Date().toISOString(),
      
      }

      let res =await axios.post(voiceCloning.streamAudio,body)

console.log(res)

setIsAudioCloning(false)
showNotification("success", "Draft file saved successfully")
if(reload) await reload();
 

    } catch (error) {
      console.log(error)
      setIsAudioCloning(false)
 
      showNotification("error" , error &&error.response && error.response.data && error.response.data.message ? error.response.data.message :"Something went wrong")
    }
  }
  return (
    <>
      <div className="px-2 flex flex-start w-100 ">
        {NotificationJSX}
        {/* <div className="flex items-center">
          <div>
            {isRecording == false && (
              <>
                <div className=" flex">
                  {targetType != "COMMENTFILE" && <IoMdMicOff size={26} />}
                </div>
              </>
            )}
          </div>
        </div> */}
        <div className="flex items-center">
          {isRecording ? (
            <>
              {/* <div className="flex item-center justify-center">
                <Button onClick={pauseRecording_1}>
                  {isPaused == true ? "Resume" : "Pause"}
                </Button>
                <Button
                  className="mx-2"
                  htmlType="button"
                  onClick={stopRecording_1}
                >
                  Stop
                </Button>
              </div> */}

              <div className="flex items-center justify-center">
                <span className="px-2">
                  {Moment.utc(secs * 1000).format("mm:ss")}
                </span>
                <Button
                  onClick={stopRecording}
                  className="hover-color-black "
                  type="button"
                >
                  Stop Recording
                </Button>
                <div className="">
                {targetType != "COMMENTFILE" && <SingleWaveform />}
                </div>
              </div>
            </>
          ) : (
            <>
              {targetType == "COMMENTFILE" ? (
                <button
                  onClick={startRecording}
                  className="remove-btn-css"
                  disabled={isDisabled || isPlayingStatus || isRecordingStatus}
                  type="button"
                >
                  <MdOutlineAudiotrack
                    size={20}
                    color={
                      (isDisabled || isPlayingStatus || isRecordingStatus) ==
                        true
                        ? "gray"
                        : "black"
                    }
                  />
                </button>
              ) : (
               <>
                <span className="px-2">
                  <Button
                    // onClick={role == "Facilitator" ? () => {
                    //   setIsModalOpen(true)
                    // } : startRecording}
                    onClick={startRecording}
                    className="hover-color-black"
                    disabled={
                      isDisabled || isPlayingStatus || isRecordingStatus || isAudioCloning
                    }
                    type="button"
                  >
                    Start Recording
                  </Button>
                </span>

                <Button onClick={recordWithAI} htmlType="button"   style={{ background: "#031832", color: "white" }}
                          loading={isAudioCloning}
                          disabled={   isDisabled || isPlayingStatus || isRecordingStatus || isAudioCloning}
                          className="submit-btn  mx-2">Record with AI</Button>
               </>
              )}

              {/* <Modal
                title={<span className="">Select Language</span>}
                open={isModalOpen}
                onOk={selectTypeOfAudio}
                onCancel={handleCancel}
                footer={
                  <>
                  <div className="script">
                  <Button
                      onClick={handleCancel}
                      style={{ background: "#F9002B", color: "white" }}
                      type="danger"
                      className="hover-text-white"
                      disabled={isLoading}
                      onMouseOver={(e) => { e.target.style.color = "white"; }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={selectTypeOfAudio}
                      className="submit-btn "
                      style={{ background: "#031832", color: "white" }}
                      onMouseOver={(e) => { e.target.style.color = "white"; }}
                    >
                      Go
                    </Button>
                  </div>
                   
                  </>
                }
              >
                {isLoading == true ? (
                  <SpinnerComponent />
                ) : (
                  <Select
                    showSearch
                    style={{
                      width: "100%",
                    }}
                    onChange={handleChange}
                    defaultValue={defaultLang}
                    >
                    <>
                      {countryCode.map((item) => (
                        <Select.Option
                          value={item.two_letter}
                          key={`${item.language}-${item.country}-${Math.random()}`}
                          className="capitalize"
                        >
                          {item.language} <span className="mx-2">({item.country})</span>
                        </Select.Option>
                      ))}
                    </>
                  </Select>
                )}
              </Modal> */}
            </>
          )}
        </div>

        {/* <div className="flex">
    {isRecording && (
          <>{targetType != "COMMENTFILE" && <SingleWaveform />}</>
        ) }
    </div> */}
      </div>

      {/* <CloneVoiceModal isOpen={isVoiceClonedModalOpen}  setIsVoiceClonedModalOpen={setIsVoiceClonedModalOpen} onSuccess={recordWithAI}/> */}
    </>

  );
};

export default AudioRecorder;