import {
  Button,
  notification,
  Table,
  Tooltip,
  Modal,
  Form,
  Input,
  Alert,
  ConfigProvider,
  Empty,
} from "antd";
import "./script.scss"
import React, { useEffect, useState } from "react";
import { FaComment, FaEdit } from "react-icons/fa";
import { MdDelete, MdDescription } from "react-icons/md";
import { useLocation } from "react-router";
import {
  extractNumberFromString,
  getCurrentTimeStamp,
  waitForAWhile,
} from "../../../services/config";
// import {
//   getDraftedFilesByChunks,
//   getDraftFileByFileID,
//   updateDraftFileIndexedDB,
// } from "../../../services/indexed-db/draftfiles.indexeddb";
// import { getProjectByRoleAndId } from "../../../services/indexed-db/project.indexeddb";
import DeleteModalComponent from "../../modal/delete-modal/delete-modal.component";
import DraftScriptModal from "../../modal/draft-scirpt-modal";
import SmallSpinnerComponent from "../../spinner/small-spinner.component";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  updateDraftedFileOnServer,
} from "../../../services/api-calls/draft-files.api";
import { s3_files_url, draftFile, translationApi } from "../../../services/apis";
import axios from "axios";
import getHeader from "../../../services/config";
import CommentsComponent from "../../comments/comments.component";
import { saveDraftFileToServer } from "../../../services/api-calls/draft-files.api";
// import {
//   saveDraftFileAndSync,
//   updateFileSelection,
// } from "../../../services/sync-methods/draft-files.sync";
import BackTranslation from "../back-translation";
import { useDispatch, useSelector } from "react-redux";
// import { saveSyncData } from "../../../services/indexed-db/sync.indexeddb";
// import { updateBooks } from "../../../services/indexed-db/books.indexeddb";
import { toggleSyncStatus, tranScriptedText, clearTranScriptedText } from "../../../redux/actions/util.action";
import FilenameComponent from "../../ui/filename.component";
import { selectDraftedFileOnServer } from "../../../services/api-calls/draft-files.api"
import { getDraftedFilesByChunksOnline } from "../../../services/api-calls/draft-files.api";
import { checkFileCount, saveCheckFilesName, toggleCheckFilesCheckbox } from "../../../redux/actions/check-files.action";
import { BsFillFileEarmarkPersonFill } from "react-icons/bs";
import { createToDoComment } from "../../../services/api-calls/comments.api";
import { isSafari } from "react-device-detect";

const { confirm } = Modal;

const DraftScriptComponent = ({
  title,
  targetType,
  fileType,
  chunk,
  relatedDraft,
  isTextAreaDisplayed,
  isBackTranslationDisplay,
  record_type,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const isPlayingStatus = useSelector((state) => state.playingStatusReducer);
  const isTranScriptedText = useSelector((state) => state.tranScriptedText);
  const projectsReducer = useSelector((state) => state.projectsReducer);
  const booksReducer = useSelector((state) => state.booksReducer);
  const isRecordingStatus = useSelector(
    (state) => state.recordingStatusReducer
  );
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [translatorLoading, setTranslatorLoading] = useState(false)
  const [isLoader, setIsLoader] = useState(false);
  const [currentSelected, setCurrentSelected] = useState({});
  const [resultantNumber, setResultantNumber] = useState("");
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [showComments, setShowComments] = useState(false);
  const [showBtScript, setShowBtScript] = useState(false);
  const [isScriptPresent, setIsScriptPresent] = useState(false);
  const [draftFileName, setDraftFileName] = useState("");
  const [script, setScript] = useState("");
  const [draftFiles, setDraftFiles] = useState({});
  const [userProject, setUserProject] = useState({});
  const [isEditScript, setIsEditScript] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [masterRecordContent, setMasterRecordContent] = useState();
  const [draftFileData, setDraftFileData] = useState();
  const [alertMessage, setAlertMessage] = useState({
    type: "",
    message: "",
  });
  // const booksReducer = useSelector((state) => state.booksReducer);
  const [api, contextHolder] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    api[type]({
      message,
    });
  };
  let role = localStorage.getItem("role");

  useEffect(() => {

    fetchFiles();
  }, [setScript, location, relatedDraft]);

  useEffect(()=>{
if(localStorage.getItem("role")== "Facilitator" && targetType == "backTranslate" && record_type?.split("_")[1] == isTranScriptedText[0]?.targetfileType){
      fetchFiles();
}
else if(localStorage.getItem("role")== "MTT" && (targetType == "draft" || targetType == "masterRecord")){
      fetchFiles();
    }
  }, [isTranScriptedText]);
  const getDraftedFiles = (targetType) => {
    return new Promise(async (resolve, reject) => {
      try {
        let files;
        let draftData = {
          projectId: location.state?.project.id,
          sourceVerseName: location.state?.verse,
          bookName: location.state?.book.bookName,
          chapterName: location.state?.chapter,
          fileType: "script",
          targetType,
        };
        if(targetType!="draft"){
          draftData['relatedDraft']=relatedDraft
        }
        files = await getDraftedFilesByChunksOnline(draftData);

        // files = await [...resp.data];
        if (files.length) {
          for (let i = 0; i < files.length; i++) {
            let fileName = await files[i].fileName.split(".")[0];
            files[i].name = fileName;
            files[i].formattedName = `${location.state?.heading}`;
            files[i].projectType = location.state?.project.projectType;
            files[i].key = files[i].fileId;
          }
        }
        resolve(files);
      } catch (error) {
        console.log("ERROR", error);
      }
    });
  };

  const fetchFiles = async () => {
    // const etag = script;
    setScript("");
    setIsLoading(true);
    setList([]);
    setIsEditScript(false);
    setIsScriptPresent(false);
    setShowComments(false);

    try {
      // let data = await getProjectByRoleAndId(
      //   localStorage.getItem("role"),
      //   localStorage.getItem("id")
      // );
      // if (location.state.length) {
      //   setUserProject(location.state.project);
      // }
      (targetType == "draft" && (role != "MTT" && role != "Facilitator")) ? setIsScriptPresent(true) : setIsScriptPresent(false);
      let currentUserId = await localStorage.getItem("id");
      let resultantNumberData = await extractNumberFromString(
        `${location.state?.verse}`
      );
      setResultantNumber(resultantNumberData);

      let name;
      if (targetType == "masterRecord" || targetType == "backTranslate") {
        name = relatedDraft;
      } else {
        name = `${location.state?.heading}`;
      }

      let allFiles = await getDraftedFiles(targetType);
      let files = await allFiles.filter((item) => item.userId === currentUserId);
      setDraftFileData(files);
      let selectedFiles = await files.filter((item) => item.isSelected == true);
      if (files && files.length > 0) {
        setIsScriptPresent(true);
        setDraftFiles(files[0]);
        setDraftFileName(files[0]?.fileName);
        let localData;
        try {
          // let heeaders = isSafari ?  {
          //   "Cache-Control": "no-cache",
          //   "If-None-Match": etag,
          // } :  {
          //   "Cache-Control": "no-cache",
          // }

          let res = await axios.get(
            `${s3_files_url}/${files[0]?.fileId}/${files[0]?.fileName}`,
            {
              headers: {"Cache-Control": "no-cache"}
            }
          );
          if (res && res.data) { 
            localStorage.setItem("selectedScript",res.data)
            setScript(res.data);
          }
          // }
        } catch (error) {
          console.log(error)
        }
        // }
      }

      if (
        localStorage.getItem("role") == "Facilitator" ||
        localStorage.getItem("role") == "Consultant" ||
        localStorage.getItem("role") == "CIT"
      ) {

        if (targetType == "backTranslate" && localStorage.getItem("role") == "Facilitator") {
          if (files && files.length > 0) {
            // files created by login user
            setList(files);
            let selectedBackTransaltion = await files.filter(
              (item) => item.isSelected == true
            );
            if (selectedBackTransaltion.length) {
              setSelectedKeys([selectedBackTransaltion[0].key]);
              setCurrentSelected(selectedBackTransaltion[0]);
              setDraftFileName(selectedBackTransaltion[0].fileName);
            }
          } else if (files && files.length == 0 && allFiles && allFiles.length > 0) {
            // File has not been created by this user, But some other user created file
            setIsScriptPresent(true);
            setList([]);
          } else if (allFiles && allFiles.length == 0) {
            // File has not been created by any user
            setIsEditScript(true);
            if (isTranScriptedText && isTranScriptedText.length) {
              for (let i = 0; i < isTranScriptedText.length; i++) {
                if (isTranScriptedText[i] != undefined && isTranScriptedText[i].record_type == record_type) {
                  setScript(isTranScriptedText[i].final_transcript);
                }
              }
            }
          }
        }
        else if (targetType == "backTranslate" && (localStorage.getItem("role") == "Consultant" ||
          localStorage.getItem("role") == "CIT")) {
          let backTranslateFiles = await allFiles.filter(item => item.targetType == "backTranslate" && item.isSelected == true);
          if (backTranslateFiles && backTranslateFiles.length > 0) {
            if (
              (backTranslateFiles[0].citStatusCheck == true &&
                localStorage.getItem("role") == "CIT") ||
              (backTranslateFiles[0].consultantStatusCheck == true &&
                localStorage.getItem("role") == "Consultant")
            ) {
              dispatch(toggleCheckFilesCheckbox(true));
              dispatch(checkFileCount(1));
            } else {
              dispatch(toggleCheckFilesCheckbox(false));
              dispatch(saveCheckFilesName(backTranslateFiles[0].fileId));
            }
            setSelectedKeys([backTranslateFiles[0].key]);
            setCurrentSelected(backTranslateFiles[0]);
            setList(backTranslateFiles)
            setIsScriptPresent(true)
            setDraftFileName(backTranslateFiles[0].fileName);
          } else {
            // localStorage.setItem(
            //   "checkPageMsg",
            //   "Script Draft is required to complete"
            // );
            // await dispatch(clearCheckFilesName());
            dispatch(toggleCheckFilesCheckbox(false));
          }
        }
        //NEED TO Test and deleted this section
        else {
          let backTranslateEnabledFile = await allFiles.filter(
            (item) =>
              item.translatorStatusCheck == true &&
              item.communityStatusCheck == true &&
              item.isSelected == true
          );
          setList([...backTranslateEnabledFile]);
          if (backTranslateEnabledFile && backTranslateEnabledFile.length > 0) {
            setSelectedKeys([backTranslateEnabledFile[0].key]);
            setCurrentSelected(backTranslateEnabledFile[0]);
            setIsScriptPresent(true);
            setShowBtScript(true);
            setDraftFileName(backTranslateEnabledFile[0].fileName);
          } else {
            setShowBtScript(false);
            localStorage.getItem("role") == "Facilitator" ? setShowBtScript(false) : setShowBtScript(true);
            dispatch(toggleCheckFilesCheckbox(false));
          }
        }
      }

      else if (localStorage.getItem("role") == "MTT") {
        if (files && files.length > 0) {
          // files created by login user
          setList(files);
          if (selectedFiles.length) {
            console.log("Selected files: ",selectedFiles)
            setSelectedKeys([selectedFiles[0].key]);
            setCurrentSelected(selectedFiles[0]);
          }
        } else if (files && files.length == 0 && allFiles && allFiles.length > 0) {
          // File has not been created by this user, But some other user created file
          setIsScriptPresent(true);
          setList([]);
        } else if (allFiles && allFiles.length == 0) {
          // File has not been created by any user
          setIsEditScript(true);
          if (isTranScriptedText && isTranScriptedText.length && targetType == "draft") {
            for (let i = 0; i < isTranScriptedText.length; i++) {
             
              if (isTranScriptedText[i] != undefined && isTranScriptedText[i].targetfileType == "draft") {
                setScript(isTranScriptedText[i].final_transcript);
                setIsEditScript(true)
                setIsScriptPresent(false)
              }
            }
          } else if (isTranScriptedText.length && targetType == "masterRecord") {
            for (let i = 0; i < isTranScriptedText.length; i++) {
              if (isTranScriptedText[i].targetfileType == "masterRecord" && isTranScriptedText[i].record_type == relatedDraft) {
                setMasterRecordContent(isTranScriptedText[i])
                setScript(isTranScriptedText[i].final_transcript);
                setIsEditScript(true)
                setIsScriptPresent(false)
              }
            }
          }
        }
      }

      if (selectedFiles.length) {
        setSelectedKeys([selectedFiles[0].key]);
        setCurrentSelected(selectedFiles[0]);
      }
    } catch (error) {
      console.log("SErver Error ", error);
    }
    setIsLoading(false);
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      render: (value, record) => (
        <>
          <FilenameComponent file={record} />
        </>
      ),
    },
    {
      title: (
        <span className={`flex items-center  justify-end mr-4-5`}>Action</span>
      ),
      dataIndex: "",
      key: "x",
      render: (item, record) => (
        <div className={`flex items-center justify-end mr-10`}>
          <span>
            <DraftScriptModal
              data={record}
              isDisabled={isLoading || isRecordingStatus || isPlayingStatus}
            />
          </span>

          {record.userId == localStorage.getItem("id") && (
            <>
              <Tooltip title="Edit">
                <Button
                  className="mx-2 hover"
                  onClick={(e) => {
                    setIsEditScript(!isEditScript);
                  }}
                  disabled={isLoading || isRecordingStatus || isPlayingStatus}
                >
                  <FaEdit />
                </Button>
              </Tooltip>
              {
                 record.isReviewed == true ||  record.comments.length<=0  ?
                <DeleteModalComponent
                  isDisabled={isLoading || isRecordingStatus || isPlayingStatus}
                  name={<MdDelete size={20} />}
                  data={record}
                  reload={fetchFiles}
                  /> : null
              }

            </>
          )}

          {currentSelected.key == record.key && (
            <>
            {
              (localStorage.getItem("role") !== "CIT" && localStorage.getItem("role") !== "Consultant") || (record.targetType !== "draft")?
             
                <Tooltip placement="topLeft" title="Comment">
                  <Button
                    className="ml-2"
                    onClick={(e) => {
                      setShowComments(!showComments);
                    }}
                    disabled={isLoading || isRecordingStatus || isPlayingStatus}
                  >
                    <FaComment size={16} />
                  </Button>
              </Tooltip> :null
            }
            </>
          )}
        </div>
      ),
    },
  ];

  const myRowSelection = {
    type: "radio",
    selectedRowKeys: [currentSelected.key],
    onChange: async (selectedRowKeys, selectedRows) => {
      SelectFileModal(selectedRowKeys.toString(), selectedRows);
    },
  };

  const SelectFileModal = (selectedRowKeys, selectedRows) => {
    confirm({
      title: <span className="  ">Are you sure you want to select this file?</span>,
      icon: <ExclamationCircleOutlined />,
      okText: "Confirm",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        try {
          let chunk = selectedRows[0].name;
          setCurrentSelected(selectedRows[0]);
          setSelectedKeys(selectedRowKeys);
          if (window.navigator.onLine == true) {
            await selectDraftedFileOnServer(`${selectedRows[0].fileId}`);
            // let data= await draftFileData.filter((item)=>selectedRows[0].fileId==item.fileId);
            // let filter={
            //   draftId:data[0].id,
            //   fileId:data[0].fileId,
            //   userId:data[0].userId,
            //   fileName:data[0].fileName,
            //   fileType:data[0].fileType,
            //   targetType:data[0].targetType,
            //   bookName:data[0].bookName,
            //   chapterName:data[0].chapterName,
            //   sourceVerseName:data[0].sourceVerseName,
            //   comments:[],
            //   isSelected:true,
            //   isReviewed:false,
            //   projectId:data[0].projectId,
            //   projectName:location.state?.project.projectName

            // }
            // await createToDoComment(filter)
          }
          // await updateFileSelection(selectedRows[0]);
          // await updateChunkData(location.state.book);
          showNotification("success", "File selected successfully");
        } catch (error) {
          showNotification(
            "error",
            "File is not selected, Please try after some time"
          );
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const updateChunkData = async (book) => {
    let chunk = location.state.chunk;
    let books = booksReducer.map((item) => {
      if (item.bookName == book.bookName) {
        let bookChapter = item.bookProgress.filter((item) => {
          item.pending -= 1;
          item.completed += 1;
        })
        let ChapterProgress = item.chaptersProgress.filter((item) => {
          if (item.chapters == location.state.chapter) {
            item.pending -= 1;
            item.completed += 1;
          }
        })
        let chapter = item.chapters.filter((item) => {
          if (item.chapterName == location.state.chapter) {
            item.chunks.map((element) => {
              if (element.chunkName == chunk) {
                element.chunksDataProgress = "completed"
              }
            });
          }
        });
      }
    })
    // await updateBooks(book.bookfileId, book);
  };
  const clearTranScript = async () => {
    let data;
    if (isTranScriptedText) {
      if (masterRecordContent && masterRecordContent.targetfileType == "masterRecord" && isTranScriptedText[0] !=undefined) {
        data = await isTranScriptedText.filter((item) => item.record_type != masterRecordContent.record_type)
      } else {
        console.log("record_type",record_type,isTranScriptedText)
        if(record_type !=undefined && isTranScriptedText[0] !=undefined){
          data = await isTranScriptedText.filter((item) =>  item.record_type != record_type)

        }
      }
    }
    await dispatch(clearTranScriptedText());
    return data;
  }
  const onFinish = async (values) => {
    setIsLoader(true);
    try {
      if (script.length <= 0) {
        showAlertMessage("error", "Please enter script");
      } else {
        if (isScriptPresent == true) {
          await updateScriptFile();
        } else {
          let uniqueKey = await getCurrentTimeStamp();
          let name = `${location.state?.chunkName} ${resultantNumber} ${uniqueKey}`;
          let scriptObj = {
            key: uniqueKey,
            name: name,
            chunk: chunk,
            blob: script,
            formattedName: `${location.state?.chunkName} ${resultantNumber}`,
            bookName: location.state?.chunk.chunk[0].bookName,
            comments: [],
            chapter: location.state?.chunk.chunk[0].chapterName,
            language: location.state.project.language,
            majorLanguage: location.state.project.majorLanguage1,
            projectId: location.state.project._id,
            sourceVerseName: location.state?.verse,
            verse: location.state?.verse,
            userId: localStorage.getItem("id"),
            targetType: targetType,
            fileId: uniqueKey,
            projectType: location.state.project.projectType,
            fileName: `${name}.txt`,
            isSelected: false,
            isDeleted: false,
            fileType: "script",
            isSynced: false,
            relatedDraft,
          };
          if (window.navigator.onLine) {
            await saveDraftFileToServer(scriptObj);
            if (isTranScriptedText.length) {
              let data = await clearTranScript();
              await dispatch(tranScriptedText(data));
            }
          }
          // await saveDraftFileAndSync(scriptObj);
          // await window.api.saveFile("saveFile", {
          //   base64: script,
          //   fileType: "script",
          //   fileName: `${name}.txt`,
          //   fileId: uniqueKey,
          // });
          showAlertMessage("success", "Script file submitted successfully");
          dispatch(toggleSyncStatus(true));
          setTimeout(() => {
            dispatch(toggleSyncStatus(false));
          }, 5000);
          setIsLoader(false);
          setScript("");
          setIsEditScript(false);
          await fetchFiles();
        }
      }
    } catch (error) {
      console.log("Upload Script File", error);
    }
    setIsLoader(false);
    // await waitForAWhile(1600);
  };

  const updateScriptFile = () => {
    return new Promise(async (resolve, reject) => {
      try {
        if (script.trim().length == 0) {
          showAlertMessage("error", "Please enter script");
          setIsLoader(false);
        } else {
          // let currentDraftFile = await getDraftFileByFileName(list[0].name);
          // let currentDraftFile;
          //Get Draft File
          // let currentDraftFile = await getDraftFileByFileID(list[0].fileId);
          // console.log("currentDraftFile", currentDraftFile);

          // await window.api.updateFile("updateFile", {
          //   base64: script,
          //   fileType: "script",
          //   fileName: `${list[0].fileName}`,
          //   fileId: list[0].fileId,
          // });

          if (navigator.onLine == true) {
            let response = await updateDraftedFileOnServer(
              `${list[0].fileId}`,
              {
                fileType: "script",
                base64: script,
                isUpdation: true,
                fileName: list[0].fileName,
              }
            ).then(async (res) => {
              if (res.msg == "File updated successfully") {
                setTimeout(() => {
                  showNotification("success", "Script File updated successfully");
                }, 1000);
                if (isTranScriptedText.length) {
                  let data = await clearTranScript();
                  await dispatch(tranScriptedText(data));
                }
              }
            })
            // let obj = {
            //   ...currentDraftFile,
            //   ...{ base64: script, fileId: response?.result?.fileId },
            // };
            // await updateDraftFileIndexedDB(list[0].fileId, {
            //   ...response.result,
            //   ...obj,
            // });
          } else {
            // if (list[0]._id) {
            //   saveSyncData({
            //     fileId: list[0].fileId,
            //     fileName: list[0].fileName,
            //     type: "UPDATE",
            //     userId: localStorage.getItem("id"),
            //   });
            // } else {
            //   saveSyncData({
            //     fileId: list[0].fileId,
            //     fileName: list[0].fileName,
            //     type: "SAVE",
            //     userId: localStorage.getItem("id"),
            //   });
            // }
          }
          setIsEditScript(false);
          setIsLoader(false);
          // showNotification("success", "Script File updated successfully");
          // await waitForAWhile(1600);
          await fetchFiles();
          resolve();
        }
      } catch (error) {
        console.log("Update Draft FIle error", error);
        setIsLoader(false);
        let message = "";
        if (error.code == "ERR_NETWORK") {
          message = "Please check internet connection";
          showAlertMessage("error", message);
        } else {
          showAlertMessage(
            "error",
            "Error while uploading the file , Contact Admin"
          );
        }
      }
    });
  };

  const onFinishFailed = () => { };

  const showAlertMessage = async (type, message) => {
    setAlertMessage({
      ...alertMessage,
      type,
      message,
    });
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1500);
  };

  const convertSourceFile = async () => {
    try {
      setTranslatorLoading(true);
      let text = await localStorage.getItem("sourceData");

      if(!text){
        showNotification("error", "Source data is not available")
      }
      let res = await axios.post(translationApi.translation, {
        text,
        to: location.state.project.language,
      }); 

      if(res.data && res.data.translatedText){
        setScript(res.data.translatedText);
      }
      setTranslatorLoading(false);
    } catch (error) {
      setTranslatorLoading(false);
      showNotification("error", error.response && error.response.data &&error.response.data.msg ? error.response.data.msg: "Error occurred while processing source data ")
      console.log(error);
    }
  };

  return (
    <>
      <div className={`pt-1 pb-2   ${isEditScript ? "" : "pb-2"}`}>
        <h4 className="fsize">
          {title}
          <span className="px-2">
            <MdDescription size={26} />
          </span>
        </h4>
        {contextHolder}
        {isLoading === true ? (
          <div className="text-center my-2 py-4  flex items-center justify-center">
            <SmallSpinnerComponent />
            <div className="loaderText mx-2">Loading</div>
          </div>
        ) : (
          <>
            {list.length >= 0 && isScriptPresent == true ? (
              <div className="source">
                <ConfigProvider
                  renderEmpty={() => (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={
                        localStorage.getItem("isBackTranslation") == "true"
                          ? "No Data" // Display "No Data" when targetType is "backTranslate" and isShowComment is true
                          : "File has been created by other user" // Display "File has been created by other user" for other cases
                      }
                    />
                  )}
                >
                  <Table
                    loading={{
                      spinning: isLoading,
                      indicator: <span>{<SmallSpinnerComponent />}</span>,
                    }}
                    rowSelection={{
                      type: "radio",
                      ...myRowSelection,
                    }}
                    pagination={role == "CIT" || role == "Consultant" ? false : {
                            pageSize: 3,
                      disabled: isPlayingStatus == true || isRecordingStatus == true ? true : false,
                    }}
                    columns={columns}
                    dataSource={list}
                  />
                </ConfigProvider>
              </div>
            ) : (
              <>
                {((localStorage.getItem("role") == "Facilitator" && targetType !== "backTranslate") ||
                  ((localStorage.getItem("role") == "Consultant" || localStorage.getItem("role") == "CIT") && targetType == "backTranslate")) ? (
                  <>
                    <ConfigProvider
                      renderEmpty={() => (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            (localStorage.getItem("role") == "Facilitator") &&
                            targetType == "draft"
                              ? "To make back translation, Cross check and Communtiy check should be completed"
                              : "No Data"
                          }
                        />
                      )}
                    >
                      <Table
                        className="pb-2"
                        pagination={role == "CIT" || role == "Consultant" ? false : {
                                pageSize: 3,
                          disabled: isPlayingStatus == true || isRecordingStatus == true ? true : false,
                        }}
                        columns={columns}
                        dataSource={list}
                        rowSelection={{
                          type: "radio",
                          ...myRowSelection,
                        }}
                      />
                    </ConfigProvider>
                  </>
                ) : null}
              </>
            )}

            {isTextAreaDisplayed == true && (
              <>
                {isEditScript == true ? (
                  <Form
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className="pt-1 mx-3"
                  >
                    {showAlert && (
                      <Alert
                        message={alertMessage.message}
                        type={alertMessage.type}
                        className="my-2 p-1"
                        showIcon
                        closable
                      />
                    )}

                    <Form.Item>
                      <Input.TextArea
                        style={{ color: "black" }}
                        showCount
                        disabled={translatorLoading}
                        maxLength={25000 * 4}
                        onChange={(e) => {
                          setScript(e.target.value);
                        }}
                        value={script || " "}
                        rows={6}
                        placeholder="Please Enter Script"
                      />
                    </Form.Item>

                    <div className="flex script">
                      {isScriptPresent == true ? (
                        <Button
                          htmlType="button"
                          style={{ background: "red", color: "white" }}
                          disabled={isLoader}
                          className="submit-btn script"
                          onClick={(e) => {
                            setIsEditScript(false);
                          }}
                          onMouseOver={(e) => { e.target.style.color = "white"; }}                        >
                          Cancel
                        </Button>
                      ) : null}
                      <Form.Item>
                        <Button
                          htmlType="submit"
                          style={{ background: "#031832", color: "white" }}
                          loading={isLoader}
                          disabled={translatorLoading}
                          className="submit-btn  mx-2"
                          onMouseOver={(e) => {
                            e.target.style.color = "white";
                          }}
                        >
                          {isScriptPresent ? "Update" : "Submit"}
                        </Button>
                      </Form.Item>

                      <Form.Item>
                        <Button
                          htmlType="button"
                          style={{ background: "#031832", color: "white" }}
                          loading={translatorLoading}
                          onClick={convertSourceFile}
                          className="submit-btn  mx-2"
                          onMouseOver={(e) => {
                            e.target.style.color = "white";
                          }}
                        >
                          Write with AI
                        </Button>
                      </Form.Item>
                    </div>
                    <div id="scroll-bottom"></div>
                  </Form>
                ) : null}
              </>
            )}
            <div className="pt-2">
              {showComments && (
                <CommentsComponent chunk={currentSelected} type="script" />
              )}
            </div>
            <>
              {showBtScript && (
                <>
                  <BackTranslation
                    // chunk={currentSelected}
                    // draftFileName={draftFileName}
                    // isButtonDisabled={
                    //   isLoading || isRecordingStatus || isPlayingStatus
                    // }
                    // fileType="script"
                    // targetType={targetType}
                    // fileData={currentSelected}
                    chunk={currentSelected}
                    draftFileName={draftFileName}
                    targetType={"draft"}
                    fileType="script"
                    fileData={currentSelected}
                    record_type={"backtranslate_script"}
                    showType={currentSelected.fileType}
                  />
                </>
              )}
            </>
          </>
        )}
      </div>
    </>
  );
};

export default DraftScriptComponent;
