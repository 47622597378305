import { Layout } from "antd";
import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
// import RhemaLoadingIcon from "../spinner/rhema-loader.component";
import FooterComponent from "../ui/footer";
import HeaderUIComponent from "../ui/header/header.ui";
import SideNavbar from "../ui/side-navbar/sidenavbar.ui";
import "./layout.scss";
import { RxHamburgerMenu } from "react-icons/rx";

const MainLayout = (props) => {
  let setNavBar = useSelector((state) => state.sideNavBarStatusReducer);
  // let isHomeLoading = useSelector((store) => store.homeLoadingReducer);

  useEffect(() => {
    const onPageLoad = () => { };
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad, false);
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);


  return (
    <div className="layout">
      <Layout>
        <Layout.Header>
          <HeaderUIComponent />
        </Layout.Header>
        <Layout>
          <>
            {localStorage.getItem("role") == "Community"?null: <Layout.Sider className={` ${setNavBar === true ? "mobile-desktop" : "desk-navbar"}`}>
              <SideNavbar />
            </Layout.Sider>}
            
            <Layout.Content>
              <div className="layout-children">{props.children}</div>
              {/* <Chatbot /> */}
            </Layout.Content>
          </>
        </Layout>
        <Layout.Footer>
          <FooterComponent />
        </Layout.Footer>
      </Layout>
    </div>
  );
};

export default MainLayout;


// return (
//   <div className="layout">
//     <Layout>
//       <Layout.Header>
//         <HeaderUIComponent />
//       </Layout.Header>
//       <Layout>
//         {isHomeLoading == true ? (
//           <RhemaLoadingIcon />
//         ) : (
//           <>
//             <Layout.Sider>
//               <SideNavbar />
//             </Layout.Sider>
//             <Layout.Content>
//               <div className="layout-children">{props.children}</div>
//               {/* <Chatbot /> */}
//             </Layout.Content>
//           </>
//         )}
//       </Layout>
//       <Layout.Footer>
//         <FooterComponent />
//       </Layout.Footer>
//     </Layout>
//   </div>
// );