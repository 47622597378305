import { Alert, Empty, Progress } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CgPlayBackwards, CgPlayForwards } from "react-icons/cg";
import { RiPauseMiniFill, RiPlayMiniFill } from "react-icons/ri";
import MainLayout from "../../components/layout/main.layout";
import TitleBar from "../../components/ui/title-bar/title-bar.component";
import Waveform from "../../components/waveform/waveform.component";
import Moment from "moment";
import { List } from "antd";
import VirtualList from "rc-virtual-list";
import StaticWaveform from "../../components/waveform/static.waveform";
import { useLocation } from "react-router";
import SpinnerComponent from "../../components/spinner/spinner.component";
// import {
//   getDraftFileByBookName
// } from "../../services/indexed-db/draftfiles.indexeddb";
import { waitForAWhile } from "../../services/config";
import CommentModal from "../../components/modal/comment-modal";
import FilenameComponent from "../../components/ui/filename.component";
import { unitArrayToBlob } from "../../services/conversion.service";
import { s3_files_url, draftFile } from "../../services/apis";
import { getDraftedFilesByBookNameOnline } from "../../services/api-calls/draft-files.api"
import WaveSurfer from "wavesurfer.js";
import SmallSpinnerComponent from "../../components/spinner/small-spinner.component";
import {isTablet,isSafari,isMobile} from 'react-device-detect';

const AudioWavePlayList = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [audios, setAudios] = useState([]);
  const [currentPlaying, setCurrentPlaying] = useState({});
  const [audioTime, setAudioTime] = useState(0);
  const [secs, setSecs] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [audioPlayingTime, setAudioPlayingTime] = useState(0);
  const [alertMessage, setAlertMessage] = useState({
    type: "",
    message: "",
  });

  const isPlaying = useRef(false);
  const isPaused = useRef(false);
  const isStopAll = useRef(false);
  const currentAudioIndex = useRef(0);
  // const countSeconds = useRef(0);
  const audioPlayingTimeRef = useRef(0);
  const audioRef = useRef();
  // const setTimeotRef = useRef();
  const lastPlayedAt = useRef(Number(0));
  const lastAudioBarTime = useRef(Number(0));
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [isAudioLoading, setIsAudioLoading] = useState(false);
  const [count, setCount] = useState(0);
  // const [chunks, setChunks] = useState([]);

  useEffect(() => {
    loadAudios();
    currentAudioIndex.current = 0;
    window.addEventListener("hashchange", () => {
      if (isPlaying.current == true) {
        stopAudio();
      }
    });

    const listener = ({ location, action }) => {
      if (action === "PUSH") {
        if (isPlaying.current == true) {
          stopAudio();
        }
      }
    };
    // const unlisten = navigator.listen(listener);
    // return unlisten;
  }, []);

  useEffect(() => {
    if (audios.length > 0 && count < 1 && !isTablet && !isSafari) {
      // Call getWaveForm when chunks have been set
      getWave(audios);
    }
  }, [audios]);

  const getWave = async (filteredAudio) => {
    console.log("GEt Wave FForrm : ",filteredAudio)
    if (wavesurfer.current || waveformRef.current) {
      (typeof filteredAudio === 'object' && !Array.isArray(filteredAudio)) && wavesurfer.current.destroy();
      wavesurfer.current = null;
    }

    setIsAudioLoading(true);
    const url = await getAduio(filteredAudio[0]||filteredAudio);
    setIsAudioLoading(false);
    console.log("In get wave form  before options")
    const options = await formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = await WaveSurfer.create(options);
    wavesurfer.current.load(url);
    setCount(count + 1)
  }

  const formWaveSurferOptions = (ref) => ({
    container: ref,
    waveColor: "#708090",
    progressColor: "#11172b",
    cursorColor: "black",
    barWidth: 0,
    barRadius: 3,
    responsive: true,
    height: 50,
    normalize: true,
    partialRender: true,
  });

  const loadAudios = () => {
    return new Promise(async (resolve, reject) => {
     try {
      if(location.state && location.state.audioFiles){
        setAudios(location.state.audioFiles);
        setIsLoading(false);
        resolve(location.state.audioFiles);
      }else{
        let result = [];

        let draftData = {
          projectId: location.state.book.projectId,
          bookName: location.state.book.bookName,
        };
        if(Array.isArray(location.state.chapter)){
         if(location.state.type == 'PLAY-SELECTED'){
          draftData.chapterName=  { $in: location.state.chapter }
         }
         else{
          const chapterNames = location.state.chapter.map(({ chapterName }) => chapterName);
          draftData.chapterName=  { $in: chapterNames }
         }
        
        }
        else{
          draftData["chapterName"]= await location.state.chapter;
        }
  
  
        let draftedFile = await getDraftedFilesByBookNameOnline(draftData);
        // let draftedFile = await [...draftData.data];
  
        let targetType;
        if (
          localStorage.getItem("role") == "Facilitator" ||
          localStorage.getItem("role") == "CIT" ||
          localStorage.getItem("role") == "Consultant"
        ) {
          targetType = "backTranslate";
        } else if (
          localStorage.getItem("role") == "MTT" ||
          localStorage.getItem("role") == "Technician"
        ) {
          targetType = "draft";
        }
  
        let filteredAudio = [];
        if (location.state.type == "PLAY-SELECTED") {
          if (localStorage.getItem("role") == "Technician") {
            let chunks = location.state.chapter;
            for (let i = 0; i < chunks.length; i++) {
              let data = await draftedFile.filter(
                (item) =>
                  item.fileType == "audio" &&
                  item.targetType == targetType &&
                  item.chapterName == chunks[i] &&
                  (item.citStatusCheck == true ||
                    item.consultantStatusCheck == true)
              );
  
              filteredAudio = [...filteredAudio, ...data];
            }
          } else {
            if (localStorage.getItem("aduioPlay") == "chapter") {
              let chunks = location.state.chapter;
              for (let i = 0; i < chunks.length; i++) {
                let data = await draftedFile.filter(
                  (item) =>
                    item.fileType == "audio" &&
                    item.targetType == targetType &&
                    item.chapterName == chunks[i]
                );
                filteredAudio = [...filteredAudio, ...data];
              }
            } else {
              let chunks = location.state.chunk;
              for (let i = 0; i < chunks.length; i++) {
                let data = await draftedFile.filter(
                  (item) =>
                    item.fileType == "audio" &&
                    (item.chapterName == location.state.chapter ||
                      item.chapter == location.state.chapter) &&
                    item.targetType == targetType &&
                    item.sourceVerseName == chunks[i]
                );
                filteredAudio = [...filteredAudio, ...data];
              }
            }
          }
          setAudios(filteredAudio);
          setIsLoading(false);
          resolve(filteredAudio);
        } else {
          let role = localStorage.getItem("role");
          if (role == "CIT" || role == "Consultant" || role == "Technician") {
            if (role == "Technician") {
              let chunks = location.state.chapter;
              for (let i = 0; i < chunks.length; i++) {
                let data = await draftedFile.filter(
                  (item) =>
                    item.fileType == "audio" &&
                    item.targetType == targetType &&
                    item.chapterName == chunks[i].chapterName &&
                    (item.citStatusCheck == true ||
                      item.consultantStatusCheck == true)
                );
                filteredAudio = [...filteredAudio, ...data];
              }
            } else {
              let chunks = location.state.book.chapters;
              // setChunks(chunks)
              for (let i = 0; i < chunks.length; i++) {
                let data = await draftedFile.filter(
                  (item) =>
                    item.fileType == "audio" &&
                    item.targetType == targetType &&
                    item.chapterName == chunks[i].chapterName
                );
                filteredAudio = await [...filteredAudio, ...data];
              }
              // await getWave(filteredAudio);
            }
          } else {
            filteredAudio = await draftedFile.filter(
              (item) =>
                item.fileType == "audio" &&
                (item.chapterName == location.state.chapter ||
                  item.chapter == location.state.chapter) &&
                item.targetType == targetType
            );
          }
          setAudios(filteredAudio);
          setIsLoading(false);
          resolve(filteredAudio);
  
        }
      }
     } catch (error) {
      setAudios([]);
      setIsLoading(false);
      resolve([]);
     }
    });
  };

  const getAduio = async (item) => {
    let url;
    url = `${s3_files_url}/${item.fileId}/${item.fileName}`;
    return (url)
  } 

  const playAllAudio = async () => {
    if (audios.length > 0) {
      await playAudio(
        audios[currentAudioIndex.current],
        currentAudioIndex.current,
        null
      );
    }
  };

  // const playAudio = async (data, index) => {
  //   try {
  //     // Destroy the existing WaveSurfer instance
  //     if (wavesurfer.current) {
  //       wavesurfer.current.destroy();
  //       wavesurfer.current = null;
  //     }
  
  //     isPlaying.current = true;
  //     setCurrentPlaying(data);
  //     currentAudioIndex.current = index;
  
  //     let url = `${s3_files_url}/${data.fileId}/${data.fileName}`;
  
  //     // Create a new WaveSurfer instance
  //     wavesurfer.current = await WaveSurfer.create(formWaveSurferOptions(waveformRef.current));
  
  //     // Load the audio file
  //     await wavesurfer.current.load(url);
  
  //     // Event listener for audioprocess
  //     wavesurfer.current.on("audioprocess", async function () {
  //       if (wavesurfer.current.isPlaying()) {
  //         const totalTimeValue = await wavesurfer.current.getDuration();
  //         const currentTimeValue = await wavesurfer.current.getCurrentTime();
  //         setAudioTime(Math.floor(totalTimeValue));
  //         setSecs(Math.floor(currentTimeValue));
  //       }
  //     });
  
  //     // Event listener for finish
  //     wavesurfer.current.on("finish", async function () {
  //       setAudioTime(0);
  //       setSecs(0);
  
  //       // Destroy the WaveSurfer instance
  //       if (wavesurfer.current) {
  //         wavesurfer.current.destroy();
  //         wavesurfer.current = null;
  //       }
  //       await waitForAWhile(1000);
  //       if (isPlaying.current) {
  //         // Play the next audio in the playlist
  //         await playNext();
  //       }
  
  //       // Create a new WaveSurfer instance for the next audio
  //       const options = await formWaveSurferOptions(waveformRef.current);
  //       wavesurfer.current = await WaveSurfer.create(options);
  
  //       // Load the next audio in the playlist
  //       await wavesurfer.current.load(url);
  //     });
  
  //     // Start playing the audio
  //     await wavesurfer.current.playPause();
  //   } catch (error) {
  //     console.error(error);
  //     showAlertMessage("error", "Contact admin, file might be deleted");
  //     await stopAudio();
  //   }
  // };

  const playAudio = async (data, index) => {
    try {
      // Destroy the existing WaveSurfer instance
      if (wavesurfer.current) {
        wavesurfer.current.destroy();
        wavesurfer.current = null;
      }
  
      isPlaying.current = true;
      setCurrentPlaying(data);
      currentAudioIndex.current = index;
  
      let url = `${s3_files_url}/${data.fileId}/${data.fileName}`;
  
      // Create a new WaveSurfer instance
      wavesurfer.current = await WaveSurfer.create(formWaveSurferOptions(waveformRef.current));
  
      // Load the audio file
      await wavesurfer.current.load(url);
  
      // Event listener for audioprocess
      wavesurfer.current.on("audioprocess", async function () {
        if (wavesurfer.current.isPlaying()) {
          const totalTimeValue = await wavesurfer.current.getDuration();
          const currentTimeValue = await wavesurfer.current.getCurrentTime();
          setAudioTime(Math.floor(totalTimeValue));
          setSecs(Math.floor(currentTimeValue));
        }
      });
  
      // Event listener for finish
      wavesurfer.current.on("finish", async function () {
        setAudioTime(0);
        setSecs(0);
  
        // Destroy the WaveSurfer instance
        if (wavesurfer.current) {
          wavesurfer.current.destroy();
          wavesurfer.current = null;
        }
        await waitForAWhile(1000);
        if (isPlaying.current) {
          // Play the next audio in the playlist
          await playNext();
        }
  
        // Create a new WaveSurfer instance for the next audio
        const options = await formWaveSurferOptions(waveformRef.current);
        wavesurfer.current = await WaveSurfer.create(options);
  
        // Load the next audio in the playlist
        await wavesurfer.current.load(url);
      });
  
      // Start playing the audio
      await wavesurfer.current.playPause();
    } catch (error) {
      console.error(error);
      showAlertMessage("error", "Contact admin, file might be deleted");
      await stopAudio();
    }
  };
  // const playAudio = async (data, index) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       // let pauseStats = await stopAudio();
  //       isPlaying.current = true;
  //       setCurrentPlaying(data);
  //       currentAudioIndex.current = index;
  //       // let url = `${s3_files_url}/${data.fileId}/${data.fileName}`;
  //       // audioRef.current = new Audio(url);
  //       let url;
  //       setIsAudioLoading(true);
  //       url = `${s3_files_url}/${data.fileId}/${data.fileName}`;
  //       setIsAudioLoading(false);
  //       console.log("In play audio",)
  //       if (!wavesurfer.current) {
  //         wavesurfer.current.on("ready", async function () {
  //           try {
  //             await wavesurfer.current.playPause();
  //           } catch (error) {
  //             console.log("ERROR", error);
  //           }
  //         });

  //       } else {
  //         await wavesurfer.current.playPause();
  //       }
  //       wavesurfer.current.on("audioprocess", async function () {
  //         if (wavesurfer.current.isPlaying()) {
  //           const totalTimeValue = await wavesurfer.current.getDuration();
  //           const currentTimeValue = await wavesurfer.current.getCurrentTime();
  //           setAudioTime(Math.floor(totalTimeValue));
  //           setSecs(Math.floor(currentTimeValue))
  //         }
  //       });

  //       wavesurfer.current.on("finish", async function () {
  //         if (wavesurfer.current) {
  //           setAudioTime(0);
  //           setSecs(0);
  //           wavesurfer.current.destroy();
  //           wavesurfer.current = null;
        
  //           if (isPlaying.current) {
  //             // Play the next audio in the playlist
  //             await playNext();
  //           }
        
  //           const options = await formWaveSurferOptions(waveformRef.current);
  //           wavesurfer.current = await WaveSurfer.create(options);
  //           await wavesurfer.current.load(url); // Load the next audio in the playlist
  //         }
  //       });
  //       // await audioRef.current.play();
  //       // let timePlay = await audioRef.current.duration;
  //       // countSeconds.current = setInterval(() => {
  //       //   setSecs((secs) => secs + 1);
  //       // }, 1000);

  //       // audioPlayingTimeRef.current = setInterval(() => {
  //       //   setAudioPlayingTime((time) => {console.log("time",time); return time + 10 / secs});
  //       // }, 1000);

  //       // setAudioTime(timePlay);
  //       // setTimeotRef.current = setTimeout(() => {
  //       //   resolve();
  //       //   if (isPlaying.current) stopAudio();
  //       // }, timePlay * 1000);
  //     } catch (error) {
  //       console.log(error);
  //       showAlertMessage("error", "Contact to admin , file might be deleted");
  //       let pauseStats = await stopAudio();
  //     }
  //   });
  // };

  // const stopAudio = () => {
  //   return new Promise(async (resolve, reject) => {
  //     console.log(" in stop: ",isPlaying.current,wavesurfer.current)
  //     if (isPlaying.current == true) {
  //       isPlaying.current = false;
  //       // dispatch(togglePlaying(false));
  //       if(wavesurfer.current){
  //         await wavesurfer.current.playPause();
  //       }
  //       // await audioRef.current?.pause();
  //       // await updateLastPlayedSecs(currentPlaying.name, 0);
  //       // await waitForAWhile(400);
  //       // audioRef.current.currentTime = 0;
  //       // clearInterval(audioPlayingTimeRef.current);
  //       // clearTimeout(setTimeotRef.current);
  //       // clearInterval(countSeconds.current);
  //       setAudioPlayingTime(0);
  //       // setAudioTime(0);
  //       // setSecs(0);
  //       lastPlayedAt.current = 0;
  //       lastAudioBarTime.current = 0;
  //       setCurrentPlaying({});
  //       audioRef.current.src = null;
  //       isStopAll.current = false;
  //       resolve("Playing is paused");
  //     } else {
  //       resolve("Player is not playing audio");
  //     }
  //   });
  // };

  // const stopAudio = () => {
  //   return new Promise(async (resolve, reject) => {
  //     console.log(" in stop: ", isPlaying.current);
  //     if (isPlaying.current == true) {
  //       isPlaying.current = false;
  
  //       // Check if wavesurfer.current is not null before using it
  //       if (wavesurfer.current) {
  //         console.log("In stop waveForm: ");
  //         await wavesurfer.current.playPause();
  //         wavesurfer.current.destroy();
  //         wavesurfer.current = null;
  //       }
  
  //       setAudioPlayingTime(0);
  //       lastPlayedAt.current = 0;
  //       lastAudioBarTime.current = 0;
  //       setCurrentPlaying({});
  //       audioRef.current.src = null;
  //       isStopAll.current = false;
  //       resolve("Playing is paused");
  //     } else {
  //       resolve("Player is not playing audio");
  //     }
  //   });
  // };

  const stopAudio = () => {
    return new Promise(async (resolve) => {
      if (isPlaying.current) {
        isPlaying.current = false;

        if (wavesurfer.current) {
          await wavesurfer.current.playPause();
          // wavesurfer.current.destroy();
          // wavesurfer.current = null;
        }

        setAudioPlayingTime(0);
        lastPlayedAt.current = 0;
        lastAudioBarTime.current = 0;
        setCurrentPlaying({});
        audioRef.current.src = null;
        resolve("Playing is paused");
      } else {
        resolve("Player is not playing audio");
      }
    });
  };
  

  // const playNext = () => {
  //   currentAudioIndex.current = currentAudioIndex.current + 1;
  //   if (currentAudioIndex.current == audios.length) {
  //     currentAudioIndex.current = 0;
  //   }
  //   if (audios.length >= 0 && audios.length > currentAudioIndex.current) {
  //     playAudio(
  //       audios[currentAudioIndex.current],
  //       currentAudioIndex.current,
  //       true
  //     );
  //   }
  // };

  // const playNext = () => {
  //   return new Promise(async (resolve, reject) => {
  //   console.log("Current Index before update:", currentAudioIndex.current,audios);
  //   await stopAudio();
  //   currentAudioIndex.current = currentAudioIndex.current + 1;
  //   if (currentAudioIndex.current == audios.length) {
  //     currentAudioIndex.current = 0;
  //   }
  //   console.log("Updated Index:", currentAudioIndex.current);

  //   if (wavesurfer.current) {
  //     console.log("In stop waveForm: ");
  //     await wavesurfer.current.playPause();
  //     wavesurfer.current.destroy();
  //     wavesurfer.current = null;
  //   }
  
  //   if (audios.length >= 0 && audios.length > currentAudioIndex.current) {
  //     // console.log("Playing next audio:", audios[currentAudioIndex.current]);
     
  //     playAudio(
  //       audios[currentAudioIndex.current],
  //       currentAudioIndex.current,
  //       true
  //     );

  
  //     // Add the following line to update the waveform for the next audio
  //     await getWave(audios[currentAudioIndex.current]);
  //     resolve();
  //   }
  // });
  // };


  const playNext = async () => {
    await stopAudio();
    if (wavesurfer.current) {
      wavesurfer.current.destroy();
      wavesurfer.current = null;
    }
    currentAudioIndex.current = currentAudioIndex.current + 1;
   
    if (currentAudioIndex.current < audios.length) {
      playAudio(audios[currentAudioIndex.current], currentAudioIndex.current, true);
      await getWave(audios[currentAudioIndex.current]);
    }
    else if(currentAudioIndex.current == audios.length){
      currentAudioIndex.current=0;
    }
  };
  

  const playPrev = () => {
    currentAudioIndex.current = currentAudioIndex.current - 1;
    if (currentAudioIndex.current == audios.length) {
      currentAudioIndex.current = 0;
    }
    if (audios.length >= 0 && audios.length > currentAudioIndex.current) {
      playAudio(
        audios[currentAudioIndex.current],
        currentAudioIndex.current,
        true
      );
    }
  };

  const showAlertMessage = async (type, message) => {
    setAlertMessage({
      ...alertMessage,
      type,
      message,
    });
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1500);
  };

  return (
    <div>
      <MainLayout>
        <div className="draft-details ">
          <div className="draft-details-wrapper h-screen">
            <TitleBar title={"Audio Player"} />

            <audio id="audio_player" ref={audioRef} />
            {showAlert && (
              <Alert
                message={alertMessage.message}
                type={alertMessage.type}
                className="my-2 p-1"
                showIcon
                closable
              />
            )}

            {isLoading ? (
              <SpinnerComponent />
            ) : (
              <div className="grid grid-cols-2 pt-4">
                <div className="mx-2">
                  <h2 className="text-center py-2 flex items-center justify-center">
                    {(
                      <>
                        {Object.keys(currentPlaying).length > 0 ? (
                          <span className="mr-2">
                            <FilenameComponent file={currentPlaying} />
                          </span>
                        ) : (
                          "Please start playing"
                        )}
                      </>
                    ) || "Please start playing"}
                  </h2>
                  <div className="mx-2 py-4">
                    {/* {isPlaying.current ? (
                      <Waveform isPlaying={isPlaying.current} lines={140} />
                    ) : (
                      <StaticWaveform lines={140} />
                    )} */}

                    {isAudioLoading && !waveformRef ? (
                      <SmallSpinnerComponent />
                    ) : <div className="container">
                      <div id="waveform" ref={waveformRef} />
                    </div>}
                  </div>
                  <div class="duration">
                    <div className="flex items-center justify-between">
                      <span> {Moment.utc(secs * 1000).format("mm:ss")} </span>
                      <span>
                        {Moment.utc(audioTime * 1000).format("mm:ss")}
                      </span>
                    </div>
                    <Progress percent={audioPlayingTime} showInfo={false} />
                  </div>
                  <div className="audio-controls flex items-center justify-center py-1">
                    <button
                      className="remove-btn-css"
                      // disabled={currentAudioIndex.current == 0 ? true : false}
                      disabled={true}
                      onClick={playPrev}
                    >
                      <CgPlayBackwards
                        size={50}
                        color={"gray"}
                      />
                    </button>
                    <span>
                      {isPlaying.current ? (
                        <RiPauseMiniFill
                          size={50}
                          onClick={(e) => {
                            isStopAll.current = true;
                            stopAudio();
                          }}
                        />
                      ) : (
                        <RiPlayMiniFill
                          size={50}
                          onClick={(e) => {
                            playAllAudio();
                          }}
                        />
                      )}
                    </span>
                    <button disabled={true} className="remove-btn-css" onClick={playNext}>
                      <CgPlayForwards color="gray" size={50} />
                    </button>
                  </div>
                </div>

                <div className="ml-4 shadow-lg p-4">
                  <h5>Audio</h5>

                  <List>
                    {audios.length > 0 ? (
                      <VirtualList data={audios} height={370} itemKey="email">
                        {(item, i) => (
                          <List.Item key={item._id}>
                            <span className="flex">
                              {Object.keys(currentPlaying).length > 0 &&
                                item.fileId == currentPlaying.fileId ? (
                                <RiPauseMiniFill
                                  size={50}
                                  className="text-theme"
                                  onClick={stopAudio}
                                />
                              ) : (
                                <RiPlayMiniFill
                                  size={50}
                                  className="text-theme"
                                  onClick={(e) => {
                                    playAudio(item, i, false);
                                  }}
                                />
                              )}
                              <span className="text-lg flex items-center">
                                {/* {item.fileName || item.name} */}
                                <span className="mr-2">
                                  <FilenameComponent file={item} />
                                </span>
                                <span className="mx-4">
                                  {item.isSelected == true && (
                                    <>
                                      <CommentModal draftFile={item} />
                                    </>
                                  )}
                                </span>
                              </span>
                              <span></span>
                            </span>
                          </List.Item>
                        )}
                      </VirtualList>
                    ) : (
                      <Empty />
                    )}
                  </List>
                </div>
              </div>
            )}
          </div>
        </div>
      </MainLayout>
    </div>
  );
};

export default AudioWavePlayList;