import React, { useEffect, useState } from "react";
import MainLayout from "../../components/layout/main.layout";
import TitleBar from "../../components/ui/title-bar/title-bar.component";
import { Button, Space, Table } from "antd";
import VideoPlayer from "../../components/video/video-player";
import { useLocation } from "react-router";
// import { getDraftFileByBookName } from "../../services/indexed-db/draftfiles.indexeddb";
import SmallSpinnerComponent from "../../components/spinner/small-spinner.component";
import CommentModal from "../../components/modal/comment-modal";
import FilenameComponent from "../../components/ui/filename.component";
import {getDraftedFilesByBookNameOnline} from "../../services/api-calls/draft-files.api"

const VideoPlaylist = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const [list, setList] = useState([]);

  useEffect(() => {
    loadFiles();
  }, []);

  const loadFiles = () => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        // let draftedFile = await getDraftFileByBookName(
        //   location.state.book.bookName
        // );

       if(location.state && location.state.videoFiles){

        setList(location.state.videoFiles);
        setIsLoading(false);
        resolve(location.state.videoFiles);
       }else{
        let draftData ={
          projectId:location.state.book.projectId,
          bookName: location.state.book.bookName,
        };
         
        let draftedFile = await getDraftedFilesByBookNameOnline(draftData);
        // let draftedFile = await [...draftData.data];


        let targetType;
        if (
          localStorage.getItem("role") == "Facilitator" ||
          localStorage.getItem("role") == "CIT" ||
          localStorage.getItem("role") == "Consultant"
        ) {
          targetType = "backTranslate";
        } else if (
          localStorage.getItem("role") == "MTT" ||
          localStorage.getItem("role") == "Technician"
        ) {
          targetType = "draft";
        }

        let filteredData = [];
        if (location.state.type == "PLAY-SELECTED") {
          if (localStorage.getItem("role") == "Technician") {
            let chunks = location.state.chapter;
            for (let i = 0; i < chunks.length; i++) {
              let data = await draftedFile.filter(
                (item) =>
                  item.fileType == "video" &&
                  item.targetType == targetType &&
                  item.chapterName == chunks[i] &&
                  (item.citStatusCheck == true ||
                    item.consultantStatusCheck == true)
              );

              filteredData = [...filteredData, ...data];
            }
          } else {
            if (localStorage.getItem("aduioPlay") == "chapter") {
              let chunks = location.state.chapter;
              for (let i = 0; i < chunks.length; i++) {
                let data = await draftedFile.filter(
                  (item) =>
                    item.fileType == "video" &&
                    item.targetType == targetType &&
                    item.chapterName == chunks[i]
                );
                filteredData = [...filteredData, ...data];
              }
            } else {
              let chunks = location.state.chunk;

              for (let i = 0; i < chunks.length; i++) {
                let data = await draftedFile.filter(
                  (item) =>
                    item.fileType == "video" &&
                    (item.chapterName == location.state.chapter ||
                      item.chapter == location.state.chapter) &&
                    item.targetType == targetType &&
                    item.sourceVerseName == chunks[i]
                );
                filteredData = [...filteredData, ...data];
              }
            }
          }

          setList(filteredData);
          setIsLoading(false);
          resolve(filteredData);
        } else {
          let role = localStorage.getItem("role");
          if (role == "CIT" || role == "Consultant" || role == "Technician") {
            if (role == "Technician") {
              let chunks = location.state.chapter;
              for (let i = 0; i < chunks.length; i++) {
                let data = await draftedFile.filter(
                  (item) =>
                    item.fileType == "video" &&
                    item.targetType == targetType &&
                    item.chapterName == chunks[i].chapterName &&
                    (item.citStatusCheck == true ||
                      item.consultantStatusCheck == true)
                );
                filteredData = [...filteredData, ...data];
              }
            } else {
              let chunks = location.state.chapter;
              for (let i = 0; i < chunks.length; i++) {
                let data = await draftedFile.filter(
                  (item) =>
                    item.fileType == "video" &&
                    item.targetType == targetType &&
                    item.chapterName == chunks[i].chapterName
                );
                filteredData = [...filteredData, ...data];
              }
            }
          } else {
            filteredData = await draftedFile.filter(
              (item) =>
                item.fileType == "video" &&
                (item.chapterName == location.state.chapter ||
                  item.chapter == location.state.chapter) &&
                item.targetType == targetType
            );
          }
          setList(filteredData);
          setIsLoading(false);
          resolve(filteredData);
        }
       }
      } catch (error) {
        console.log(error);
      }
    });
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => <FilenameComponent file={record} />,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle" className="hover">
          <VideoPlayer data={record} className="hover" fileType={"DRAFTFILE"} />

          {record?.isSelected == true ? (
            <Button>
              <CommentModal draftFile={record} />
            </Button>
          ) : null}
          {/* <DeleteModalComponent
            name={<MdDelete size={20} />}
            data={record}
            reload={loadFiles}
          /> */}
        </Space>
      ),
    },
  ];

  // const playVideo = async (data) => {
  //   let b64 = data.base64.split("data:video/mp4;base64,")[1];
  //   let v = await b64toBlob(b64, "video/mp4");
  //   let videoUrl = await URL.createObjectURL(v);
  //   setUrl(videoUrl);
  // };

  return (
    <div>
      <MainLayout>
        <div className="draft-details ">
          <div className="draft-details-wrapper h-screen">
            <TitleBar title={"Video Player"} />
            <div className="pt-4">
              <Table
                loading={{
                  spinning: isLoading,
                  indicator: (
                    <span>
                      <SmallSpinnerComponent />
                    </span>
                  ),
                }}
                columns={columns}
                dataSource={list}
                pagination={{
                  pageSize: 5,
                }}
              />
            </div>
          </div>
        </div>
      </MainLayout>
    </div>
  );
};

export default VideoPlaylist;
