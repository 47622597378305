import React from "react";
import { CommentOutlined, CustomerServiceOutlined } from "@ant-design/icons";
import { FloatButton } from "antd";
import { FaMusic } from "react-icons/fa6";
import { FaVideo } from "react-icons/fa";

const App = () => (
  <>
    <FloatButton.Group
      trigger="click"
      type="primary"
      style={{
        insetInlineEnd: 24,
      }}
      icon={<CustomerServiceOutlined />}
    >
      <FloatButton />
      <FloatButton icon={<FaMusic />} />
      <FloatButton icon={<FaVideo />} />
    </FloatButton.Group>
  </>
);
export default App;
