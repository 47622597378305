import React, { useEffect, useState } from "react";
import { Input } from "antd";
import axios from "axios";
import { s3_files_url } from "../../../services/apis";
import SmallSpinnerComponent from "../../spinner/small-spinner.component";
const { TextArea } = Input;
const ScriptTextBox = ({ item }) => {
  const [script, setScript] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadScriptFile(item);
  }, [item]);

  const loadScriptFile = async (item) => {
    try {
      let data;
      setIsLoading(true);
    
        let res = await axios.get(
          `${s3_files_url}/${item.fileId}/${item.fileName}`
        );
        data = res.data;
      

        localStorage.setItem("sourceData", JSON.stringify(data));

      setScript(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setScript("");
      setIsLoading(false);
    }
  };

  return (
    <div className="mx-2">
      {isLoading == true ? (
        <span>
          <SmallSpinnerComponent />
        </span>
      ) : (
        <>
          <TextArea 
          style={{ color:"black",backgroundColor: "#fff",
          borderColor:"#fff",border:"none",overflow:"auto" }} 
          rows={4} cols={100} value={script} disabled={true} />
        </>
      )}
    </div>
  );
};

export default ScriptTextBox;
